<template>

  <v-container fluid fill-height fill-width class="justify-center" v-if="ready">
    <v-dialog
      v-model="disclaimer"
      width="auto"
      :persistent="!informative"
    >
      <v-sheet
      class="pa-6 text-white mx-auto"

      max-width="400"
    >
      <h4 class="text-h5 font-weight-bold mb-4">Your Privacy</h4>

      <p class="">
        This privacy policy is adapted to the current Spanish and European regulations on the protection of personal data on the internet. Specifically, it adheres to the following rules:
      </p><p class="">
        <ol>
          <li>Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, on the protection of natural persons concerning the processing of personal data and on the free movement of such data (GDPR).
          </li>
          <li> Organic Law 3/2018, of December 5, on the Protection of Personal Data and the guarantee of digital rights (LOPD-GDD).</li>
          <li> Law 34/2002, of July 11, on Information Society Services and Electronic Commerce (LSSI-CE).</li>
        </ol>
      </p><p class="">
        In compliance with the provisions of the GDPR and LOPD-GDD, we inform you that the personal data collected by LIGHT BRIDGES, S.L., as data controller, through the forms on its pages will be incorporated and processed in our files in order to facilitate, expedite, and fulfill the commitments established between the owner and the user or the maintenance of the relationship established in the forms that the latter fills out, or to attend to a request or inquiry from the user. Likewise, in accordance with the provisions of the GDPR and LOPD-GDD, unless the exception provided for in article 30.5 of the GDPR applies, a record of processing activities is kept specifying, according to their purposes, the processing activities carried out and the other circumstances established in the GDPR.
      </p><p class="">
        The categories of data processed by the owner are only identifying data. In no case are special categories of personal data processed within the meaning of Article 9 of the GDPR.
      </p><p class="">
        The legal basis for the processing of personal data is consent. The owner undertakes to obtain the express and verifiable consent of the user for the processing of their personal data for one or more specific purposes.
      </p><p class="">
        The user has the right to withdraw their consent at any time. As a general rule, withdrawing consent will not affect the use of the website.
      </p><p class="">
        On occasions when the user must or may provide their data through forms to make inquiries, request information, or for reasons related to the content of the website, they will be informed if the completion of any of them is mandatory because they are essential for the proper development of the operation carried out.
      </p><p class="">
        Personal data is collected and managed by the owner for the purpose of facilitating, expediting, and fulfilling the commitments established between the website and the user or the maintenance of the relationship established in the forms filled out by the latter or to attend to a request or inquiry.
      </p><p class="">
        Likewise, the data may be used for commercial purposes of personalization, operation, and statistics, and activities inherent to the corporate purpose of the owner, as well as for the extraction, storage of data, and marketing studies to adapt the Content offered to the user, as well as to improve the quality, operation, and navigation of the website.
      </p><p class="">
        At the time personal data is obtained, the user will be informed about the specific purpose or purposes of the processing to which the personal data will be used; in other words, the use or uses that will be given to the collected information.
      </p><p class="">
        Personal data will only be retained for the minimum time necessary for the purposes of its processing and, in any case, only for 5 years or until the user requests its deletion.
      </p><p class="">
        At the time personal data is obtained, the user will be informed about the period during which the personal data will be retained or, when that is not possible, the criteria used to determine this period.
      </p><p class="">
        The sole recipient and controller of your personal data is  LIGHT BRIDGES, S.L., a Spanish company, with registered office at Avenida Alcalde José Ramírez Bethencourt, 17, Las Palmas de Gran Canarias (Canary Islands), registered in the Mercantile Register of Las Palmas de Gran Canaria, at volume 2245, folio 138, page GC-57164 and with tax identification number B-01794288
      </p><p class="">
        The user may exercise the following rights recognized in the GDPR and Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights:
        (i) Right of access: it is the user’s right to obtain confirmation of whether the owner is processing their personal data and, if so, obtain information about their specific personal data and the processing carried out or to be carried out, as well as, among other things, information available on the origin of such data and the recipients of the communications made or planned for them; (iii) Right of rectification: it is the user’s right to have their personal data modified if it is inaccurate or, taking into account the purposes of the processing, incomplete; (ii) Right of deletion: it is the user’s right, whenever current legislation does not establish otherwise, to obtain the deletion of their personal data when it is no longer necessary for the purposes for which it was collected or processed; the user has withdrawn their consent to the processing and there is no other legal basis; the user opposes the processing and there is no other legitimate reason to continue it; the personal data has been processed unlawfully; the personal data must be deleted to comply with a legal obligation; or the personal data has been obtained as a result of a direct offer of information society services to a child under 14 years of age. In addition to deleting the data, the data controller, taking into account the available technology and the cost of its implementation, must take reasonable measures to inform the data controllers who are processing the personal data of the interested.(iv) Rights of limitation: Personal data of the user shall only be collected for specified, explicit, and legitimate purposes and not further processed in a manner that is incompatible with those purposes. When this does not occur, the user has the right to request to limit the way that an organisation uses their data.; and (v) Rights of portability: The right to data portability allows individuals to obtain and reuse their personal data for their own purposes across different services.
        Any data subject who wishes to exercise any of the aforementioned rights may do so at the address of the data controller set forth in this privacy policy or at the following email address [...] making clear reference in the subject of the message to the exercise of the corresponding right and addressing the email to the attention of [...]
        In any case, the user is entitled to seek the protection of the Spanish Data Protection Agency through its website www.agpd.es.

      </p>

      <v-btn
        class="text-none text-black mb-4"
        color="red-accent-2"
        size="x-large"
        variant="flat"
        block
        @click="acceptDisclaimer"
        v-if="!informative"
      >
        Accept
      </v-btn>

      <v-btn
        class="text-none text-black"
        color="red-accent-2"
        size="x-large"
        variant="outlined"
        block
        @click="disclaimer = false; loading = false"
        v-if="!informative"
      >
        Cancel
      </v-btn>
      <v-btn
        class="text-none text-black"
        color="red-accent-2"
        size="x-large"
        variant="outlined"
        block
        @click="disclaimer = false; loading = false"
        v-if="informative"
      >
        Close
      </v-btn>
    </v-sheet>
    </v-dialog>
    <div class="justify-center d-flex flex-column align-center">
      <v-img src="@/assets/img/logo.png" width="35%" />
    <v-card class="login-box pb-5 pl-5 pr-5" :loading="loading" outlined> 
      <v-card-title class="d-flex flex-column justify-space-between align-center mt-5">
        <span class="text-h5 font-weight-light my-3">{{ $t('Log in') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @keyup.native.enter="handleSubmit">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
                v-model="username"
                append-icon="mdi-account"
                :label="$t('Username')"
                placeholder=" "
                persistent-placeholder
                type="text" outlined
                :error="this.submitted && !this.username">
            </v-text-field>
            <v-text-field
                v-model="password"
                append-icon="mdi-lock"
                :label="$t('Password')"
                placeholder=" " outlined
                persistent-placeholder
                type="password"
                :error="this.submitted && !this.password">
            </v-text-field>
            <div class="text-right">
              <a>{{ $t("Forgot your password?") }}</a>
            </div>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-5">
        <v-btn text color="primary lighten-1" disabled>
          {{ $t('Sign up') }}
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" :disabled="loading" @click="handleSubmit">
          {{ $t('Sign in') }}
        </v-btn>
      </v-card-actions>
      <div class="text-right">
              <a class="text-body-2" small @click="informative = true; disclaimer=true;">{{ $t("Privacy Policy") }}</a>
            </div>
    </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
    name: 'LoginView',
    data () {
        return {
            username: '',
            password: '',
            ready: false,
            loading: false,
            submitted: false,
            disclaimer:false,
            informative:false,
        }
    },
    mounted() {
      if (this.$store.getters["auth/loggedIn"]) {
        if (this.$route.query.next) {
          this.$router.push(this.$route.query.next);
        } else {
          this.$store.dispatch("auth/logout");
        }
      }
      this.ready = true;
    },
    methods: {
      
      handleSubmit() {
        this.informative = false,
        this.submitted = true;
        let { username, password } = this;
        if (username && password) {
          this.loading = true;
          this.$apiRequest('post','auth/get-token/',{
            data: {
              username: username,
              password: password
            }
          }).then((res) => { 
            let token = res.data.access
            this.$apiRequest('get', "disclaimer/?user="+username,{
              headers: {
                'Authorization': 'Bearer'+ token
              }
            }).then((res) => {   
              if (res.data){
                this.$store.dispatch(
                  'auth/login',
                  { username, password }
                  ).then((response) => {
                    this.loading = false;         
                    if (response.status == 200) {
                      this.$store.dispatch("alert/close");
                      this.$router.push(this.$route.query.next || "/")
                    }
                  })
              }
              else{
                this.disclaimer=true
              }
            })
          })
        }
      },
      acceptDisclaimer(){
        let { username, password } = this;
        this.$store.dispatch(
          'auth/login',
          { username, password }
        ).then((response) => {
          this.loading = false;         
          if (response.status == 200) {
            this.$apiRequest('post', 'disclaimer/', {
              data: {
                user: username,
                accepted: true
              }
            }).then((res) => {
              if (res.status == 200) {
                this.disclaimer=false
                this.$store.dispatch("alert/close");
                this.$router.push(this.$route.query.next || "/")
              }
            })
          }
        }) 
      }
    }
};
</script>

<style scoped>
.login-box {
  min-width: 200px;
  width: 400px;
}
</style>